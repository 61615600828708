import './dashboard.css';

import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import axios from "axios";
import moment from 'moment';

//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable, { ExpanderComponentProps, defaultThemes } from 'react-data-table-component';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/free-regular-svg-icons';

//https://www.npmjs.com/package/react-tabs
//https://newyork-anthonyng.github.io/gitbooks_test/activetabclassname.html
//https://reactcommunity.org/react-tabs/
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import History from "./history.js"
import Map from "./map.js"
import Maprt from "./maprt.js"
import Maprtnew from "./maprtnew.js"
import Account from './account.js';
import Interceptor from './interceptor.js';
import Whitelist from './whitelist.js';
import Accountdevice from './accountdevice.js';
import Groups from './groups.js';
import Alerts from './alerts.js';
import Passwordchange from './pwdchange.js';
import BarChart from './barchart.js';


export default function Main() {

	const [isadmin, setIsadmin] = useState();
	const [user, setUser] = useState();
	const [accountid, setAccountid] = useState();

	useEffect(() => {
		const items = JSON.parse(localStorage.getItem('token'));
		if (items) {
			setIsadmin(items.admin);
			setUser(items.token)
			setAccountid(items.id)
		}

		const handleTabClose = (event: BeforeUnloadEvent) => {
			logout();
		};

		window.addEventListener('beforeunload', handleTabClose);

		return () => {
			window.removeEventListener('beforeunload', handleTabClose);
		};
	}, []);

	const conditionalRowStyles = [
		{
			when: row => row.status === 0 || row.status === 1,
			style: {
				backgroundColor: 'rgba(63, 195, 128, 0.9)',
			},
		},
		{
			when: row => row.status === 255,
			style: {
				backgroundColor: 'lightgrey',
			},
		},
		{
			when: row => row.active === false,
			style: {
				backgroundColor: 'salmon',
			},
		}
	];

	const conditionalRowStyles_rt = [

		{
			when: row => row.status === 0 || row.status === 1,
			style: {
				backgroundColor: 'lightgreen',
			},
		},
		{
			when: row => row.status === 255,
			style: {
				backgroundColor: 'lightgrey',
			},
		}
	];

	const Label = styled.label`
	height: 32px;
	width: 90px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	text-align: center;
	padding-top: 9px
`;

	const TextField = styled.input`
	height: 32px;
	width: 350px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	&:hover {
		cursor: pointer;
	}
`;

	const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-color: lightgrey;
	height: 34px;
	width: 64px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color:darkgrey;
	&:hover {
		cursor: pointer;
	}
`;

	const FilterComponent_Interceptor = ({ filterText, onFilter, onClear }) => (
		<>
			<TextField
				id="search"
				type="text"
				placeholder="Filter on Imei, Name or Seal."
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<ClearButton type="button" onClick={onClear}>Clear</ClearButton>
		</>
	);

	const FilterComponent = ({ filterText, onFilter, onClear }) => (
		<>
			<TextField
				id="search"
				type="text"
				placeholder="Filter on Imei, Mac, Name or Manufacturer"
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<ClearButton type="button" onClick={onClear}>Clear</ClearButton>
		</>
	);

	//https://react-data-table-component.netlify.app/?path=/story/pagination-remote--remote

	interface Row {
		title: string;
		director: string;
		year: string;
	}

	interface Props extends ExpanderComponentProps<Row> {
		// currently, props that extend ExpanderComponentProps must be set to optional.
		someTitleProp?: string;
	}

	type ImeiProps = {
		imei: string;
	};

	const ExpandableRowComponent: FC<Props> = ({ data, someTitleProp }) => {
		//const [imei, setImei] = useState();
		return (
			<>
				<FilteringBLE_RT
					imei={data.udi}>
				</FilteringBLE_RT>
			</>
		);
	};

	const ExpandableRowComponent_RT: FC<Props> = ({ data, someTitleProp }) => {
		return (
			<>
				<div className='cSpecials'>
					<ul>
						<li>DB id:{data.ble_dataid}</li>
						<li>Power:{data.pwr}</li>
						<li>Satelites:{data.fix}</li>
						<li>Speed:{data.spd}</li>
						<li>Altitude:{data.alt}</li>
						<li>Accurancy:{data.acc}</li>
						<li>Battery:{(data.battery / 1000).toFixed(2)} Volt.</li>
						<li>Latitude:{data.latitude}</li>
						<li>Longitude:{data.longitude}</li>
						<li>Status:{data.status}</li>
					</ul>
				</div>
			</>
		);
	};

	const customStyles = {
		header: {
			style: {
				minHeight: '56px',
			},
		},
		headRow: {
			style: {
				borderTopStyle: 'solid',
				borderTopWidth: '1px',
				borderTopColor: defaultThemes.default.divider.default,
			},
		},
		headCells: {
			style: {
				'&:not(:last-of-type)': {
					borderRightStyle: 'transparant',
					borderRightWidth: '1px',
					borderRightColor: defaultThemes.default.divider.default,
				},
			},
		},
		cells: {
			style: {
				'&:not(:last-of-type)': {
					borderRightStyle: 'transparant',
					borderRightWidth: '1px',
					borderRightColor: defaultThemes.default.divider.default,
				},
			},
		},
	};

	const columns_Interceptor = [
		{
			name: 'Last update',
			selector: row => moment(row.updatetime).format('HH:mm:ss DD-MM-YY'),
			sortable: true,
			center: 'true'
		},
		{
			name: 'Imei',
			selector: row => row.udi,
			sortable: true,
			center: 'true'
		},
		{
			name: 'Seal',
			selector: row => row.seal,
			sortable: true,
			center: 'true'
		},
		{
			name: 'Name',
			selector: row => row.devicename,
			sortable: true,
		},
		{
			name: 'Devices',
			selector: row => row.devicecount,
			sortable: true,
			center: 'true'
		},
		{
			name: 'Satelites',
			selector: row => row.fix,
			sortable: true,
			center: 'true'
		},
		{
			name: 'Battery %',
			//selector: row => row.battery,
			cell: row => row.battery >= 0 && (
				<input className='cinput'
					value={row.battery}
					title={row.voltage + ' Volt.'} />
			),
			sortable: 'false',
			center: 'true',
			width: '105px',
			conditionalCellStyles: [
				{
					when: row => row.battery >= 1 && row.battery < 10,
					style: {
						backgroundColor: 'orange',
					},
				},
				{
					when: row => row.battery === 0,
					style: {
						backgroundColor: 'red',
					},
				},
			]
		},
		{
			name: 'Chargestate',
			selector: row => row.chargetype,
			center: 'true',
			sortable:'true'
		},
		{
			name: 'Active',
			selector: row => <input type="checkbox" readOnly checked={row.active} />,
			center: 'true'
		},
		{
			name: 'Alert',
			selector: row => <input type="checkbox" readOnly checked={row.alertactive} />,
			center: 'true'
		},
		{
			name: 'Location',
			cell: row => (row.latitude !== 0 && row.longitude !== 0) && (
				<a target="_blank" href={"https://www.google.nl/maps/place/" + row.latitude + "," + row.longitude} rel="noreferrer">
					<FontAwesomeIcon icon={faMap} title="Show on Google map"></FontAwesomeIcon>
				</a>
			),
			center: 'true',
		},
		{
			name: 'Priority',
			cell: row => row.prioritytype,
			omit: !isadmin,
			sortable: true,
			center: 'true'
		},
		{
			name: 'Version',
			cell: row => row.version,
			omit: !isadmin,
			sortable: true,
			center: 'true'
		}
	];

	const RSSI_COLORS = [
		{ max: -50, color: [0, 255, 0] },        // Green
		{ max: -60, color: [173, 255, 47] },     // Green-Yellow
		{ max: -70, color: [255, 255, 0] },      // Yellow
		{ max: -80, color: [255, 165, 0] },      // Orange
		{ max: -90, color: [255, 69, 0] },       // Orange-Red
		{ max: Infinity, color: [255, 0, 0] }    // Red
	];

	// Functie om de kleur te interpoleren
	const interpolateColor = (color1, color2, factor = 0.25) => {
		return color1.map((c, i) => Math.round(c + factor * (color2[i] - c)));
	};

	// Functie om de RSSI-kleur te verkrijgen
	const getRSSIColor = (rssi) => {
		for (let i = 0; i < RSSI_COLORS.length - 1; i++) {
			if (rssi <= RSSI_COLORS[i].max) {
				let next = RSSI_COLORS[i + 1];
				let factor = (rssi - next.max) / (RSSI_COLORS[i].max - next.max);
				const color = interpolateColor(next.color, RSSI_COLORS[i].color, factor);
				return 'rgb(' + color.join(",") + ')';
			}
		}
		return "rgb(255,0,0)";  // Default color als er geen match is
	};

	// Voorbeeld React component die de kleur op basis van RSSI weergeeft
	function RSSIIndicator(rssi) {
		const color = getRSSIColor(rssi);
		////console.log(color)
		return color;
	};

	const columns_rt = [
		{
			name: 'Date',
			selector: row => moment(row.received).format('HH:mm:ss DD-MM-YY'),
			sortable: 'true',
			width: '180px',
		},
		{
			name: 'Imei',
			selector: row => row.udi,
			sortable: 'true',
			width: '180px',
		},
		{
			name: 'Mac',
			selector: row => row.mac,
			sortable: 'true',
			width: '180px',
		},
		{
			name: 'Name',
			selector: row => row.name,
			sortable: 'true',
		},
		{
			name: 'Manufacturer',
			selector: row => row.manufacturer,
			sortable: 'true',
		},
		{
			name: 'Rssi',
			selector: row => row.rssi,
			sortable: 'true',
			center: 'true',
			width: '100px',
			conditionalCellStyles: [
				{
					when: row => row.rssi < 0,
					style: row => ({
						backgroundColor: RSSIIndicator(row.rssi),
					}),
				},
			]
		},
		{
			name: 'Time',
			selector: row => row.regtime,
			sortable: 'true',
			center: 'true'
		},
		{
			name: 'Updated',
			selector: row => moment(row.updatetime).format('HH:mm:ss DD-MM-YY'),
			sortable: 'true',
			center: 'true',
		},
		{
			name: 'Alert',
			cell: row => row.alarm,
			sortable: 'true',
			center: 'true',
			width: '100px',
			conditionalCellStyles: [
				// You can also pass a callback to style for additional customization
				{
					when: row => row.alarm === 1,
					style: row => ({
						backgroundColor: 'orange',
					}),
				},
			]
		},
		{
			button: 'true',
			omit: !isadmin,
			cell: (row) => (
				<Button variant="warning"
					onClick={(e) => {

						const confirmBox = window.confirm(
							"Do you really want to whitelist this mac?"
						)
						if (confirmBox === true) {
							handleWhitelistMac(e, row.mac, row.name)
							//e.target.disabled = true;
						}
					}}>
					Whitelist
				</Button>
			)
		},
		{
			button: 'true',
			omit: true, //!isadmin,
			cell: (row) => (
				<Button variant="warning"
				>
					Rssi alert
				</Button>
			)
		}
	];

	const paginationComponentOptions = {
		selectAllRowsItem: 'true',
		selectAllRowsItemText: 'All',
		paginationPerPage: 30
	};

	const fixedHeaderScrollHeight = '1150px'
	const fixedHeader = false;
	const dence = true;

	const logout = () => {
		//console.log('Logoff')
		localStorage.removeItem("token");
		window.location.reload();
	};

	async function handleWhitelistMac(e, mac, name) {
		e.preventDefault();
		return fetch('https://interceptor.skylab.nl:2807/whitelistmac', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ mac: mac, name: name })
		})
			.then(function (response) {
				if (response.status === 200) {
					////console.log(response)
				}
				handleAccountAction('Whitelist:' + mac + ' name:' + name)
				//return response.text();
			})
			.catch(function (error) {
				console.error(error);
			})
	};

	async function handleAccountAction(accountAction) {
		//Get accountid from local storage

		const items = JSON.parse(localStorage.getItem('token'));
		const accountid = items.id;

		//console.log(accountid, accountAction)

		return fetch('https://interceptor.skylab.nl:2807/accountaction', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ accountid: accountid, action: accountAction })
		})
			.then(function (response) {
				return response.text();
			})
			.catch(function (error) {
				console.error(error);
			})
	};

	const FilteringBLE_Interceptor = () => {
		const [datainterseptor, setDatainterseptor] = useState([]);
		const [loading, setLoading] = useState(false);

		useEffect(() => {
			const timer = setInterval(() => {
				setDatainterseptor(prev => {
					////console.log('Timer reload interceptor:', props.imei)
					fetchAccountdevices();
					return [...prev];
				});
			}, 10000);

			return () => {
				clearInterval(timer);
			};
		}, []);

		const fetchAccountdevices = async page => {
			const items = JSON.parse(localStorage.getItem('token'));
			setLoading(false);
			//Hier de interceptoren ophalen voor de groepen van de account
			////console.log('item.usergroup:',items.usergroup.join(','))
			const response = await axios.post(`https://interceptor.skylab.nl:2807/interceptor`,
				{ accountid: items.id }
			);
			setDatainterseptor(response.data);
			setLoading(false);
		};

		useEffect(() => {
			fetchAccountdevices();
		}, []);

		const [filterText, setFilterText] = useState('');
		const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
		const filteredItems = datainterseptor.filter(
			item =>
				(item.udi && item.udi.toLowerCase().includes(filterText.toLowerCase())) ||
				(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
				(item.seal && item.seal.toLowerCase().includes(filterText.toLowerCase())),

		);

		const subHeaderComponentMemo = useMemo(() => {
			const handleClear = () => {
				if (filterText) {
					setResetPaginationToggle(!resetPaginationToggle);
					setFilterText('');
				}
			};

			return (
				<FilterComponent_Interceptor onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
			);
		}, [filterText, resetPaginationToggle]);

		return (
			<>
				<DataTable
					columns={columns_Interceptor}
					data={filteredItems}
					progressPending={loading}
					pagination
					paginationComponentOptions={paginationComponentOptions}
					conditionalRowStyles={conditionalRowStyles}
					expandableRows expandableRowsComponent={ExpandableRowComponent}
					subHeader
					subHeaderComponent={subHeaderComponentMemo}
					persistTableHead
					highlightOnHover
					customStyles={customStyles}
					fixedHeader={fixedHeader}
					fixedHeaderScrollHeight={fixedHeaderScrollHeight}
					dence={dence}
					paginationPerPage={20}
					defaultSortFieldId="Imei"
				/>
			</>
		);
	};

	const FilteringBLE_RT = (props: ImeiProps) => {
		const [data, setData] = useState([]);
		const [loading, setLoading] = useState(false);

		useEffect(() => {
			const timer = setInterval(() => {
				setData(prev => {
					////console.log('Timer reload imei:', props.imei)
					fetch_RT();
					return [...prev];
				});
			}, 5000);

			return () => {
				clearInterval(timer);
			};
		}, []);

		const fetch_RT = async page => {
			setLoading(false);
			const response = await axios.get('https://interceptor.skylab.nl:2807/data/' + props.imei);
			////console.log('Reloaded imei:', props.imei)
			setData(response.data);
			setLoading(false);
		};

		useEffect(() => {
			fetch_RT();
		}, []);

		const [filterText, setFilterText] = useState('');

		const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
		const filteredItems = data.filter(
			item =>
				(item.udi && item.udi.toLowerCase().includes(filterText.toLowerCase())) ||
				(item.mac && item.mac.toLowerCase().includes(filterText.toLowerCase())) ||
				(item.manufacturer && item.manufacturer.toLowerCase().includes(filterText.toLowerCase())) ||
				(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())),
		);

		const subHeaderComponentMemo = useMemo(() => {
			const handleClear = () => {
				if (filterText) {
					setResetPaginationToggle(!resetPaginationToggle);
					setFilterText('');
				}
			};

			return (
				<>
					<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
				</>
			);
		}, [filterText, resetPaginationToggle]);

		return (
			<>
				<DataTable
					columns={columns_rt}
					data={filteredItems}
					progressPending={loading}
					pagination
					paginationComponentOptions={paginationComponentOptions}
					conditionalRowStyles={conditionalRowStyles_rt}
					expandableRows expandableRowsComponent={ExpandableRowComponent_RT}
					subHeader
					subHeaderComponent={subHeaderComponentMemo}
					persistTableHead
					//highlightOnHover
					customStyles={customStyles}
					fixedHeader={fixedHeader}
					fixedHeaderScrollHeight={fixedHeaderScrollHeight}
					dence={dence}
					paginationPerPage={10}
				/>
			</>
		);
	};

	return (
		<>
			<div className="cDashboard">
				<header className="App-header">
					Interceptor Overview - {user}
					<Button className='cButtonRight' variant="warning"
						onClick={(e) => {
							logout();
						}}>
						Log off
					</Button>
				</header>

				<Tabs>
					<TabList>
						<Tab>Realtime</Tab>
						<Tab>RT Map</Tab>
						<Tab>Multi RT Map</Tab>
						<Tab>History Map</Tab>
						<Tab>History</Tab>
						<Tab disabled={!isadmin}>Administration</Tab>
						<Tab>Devices chart</Tab>
					</TabList>

					<TabPanel>	{/* Realtime */}
						<FilteringBLE_Interceptor></FilteringBLE_Interceptor>
					</TabPanel>
					<TabPanel>	{/* Realtime map*/}
						<Maprt></Maprt>
					</TabPanel>
					<TabPanel>	{/* Realtime map new*/}
						<Maprtnew></Maprtnew>
					</TabPanel>
					<TabPanel>	{/* map */}
						<Map></Map>
					</TabPanel>
					<TabPanel>	{/* history */}
						<History></History>
					</TabPanel>
					<TabPanel>	{/* Administrator */}
						<Tabs>
							<TabList>
								<Tab>Account</Tab>
								<Tab>Interceptor</Tab>
								<Tab>Whitelist</Tab>
								<Tab>Group</Tab>
								<Tab>Change passwords</Tab>
								{/* <Tab>Alert</Tab> */}
							</TabList>

							<TabPanel>	{/* accounts */}
								<Account></Account>
							</TabPanel>

							<TabPanel>	{/* Interceptor */}
								<Interceptor></Interceptor>
							</TabPanel>

							<TabPanel>	{/* Whitelist */}
								<Whitelist></Whitelist>
							</TabPanel>

							<TabPanel>	{/* User Interceptor Groups */}
								<Groups></Groups>
							</TabPanel>

							<TabPanel>	{/* User Change Password */}
								<Passwordchange></Passwordchange>
							</TabPanel>
						</Tabs>
					</TabPanel>
					<TabPanel>	{/* history */}
						<BarChart></BarChart>
					</TabPanel>
				</Tabs>
			</div>
		</>
	);
}