import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from "axios";
//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable, { defaultThemes } from 'react-data-table-component';

//https://react-bootstrap.netlify.app/docs/forms/input-group
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Switch from 'react-bootstrap/Switch';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FormSelect from 'react-bootstrap/FormSelect'
import styled from 'styled-components';
import './interceptor.css';

export default function Interceptor() {

  // const [udi, setUdi] = useState();
  // const [devicename, setDevicename] = useState();
  const [data, setData] = useState([]);
  const [active, setActive] = useState(false)
  const [alertactive, setAlertActive] = useState(false)
  const [accountid, setAccountid] = useState();
  const [usergroup, setUsergroup] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [prioritylevel, setPrioritylevel] = useState([]);
  const [headertext, setHeadertext] = useState('New interceptor')
  const [btnSavetext, setBtnSavetext] = useState('Save')
  const [ButtonAvailable, setButtonAvailable] = useState(false)
  const [isImeiFieldDisabled, setImeiFieldDisabled] = useState(false);
  const [dbusergroup, setDbusergroup] = useState('');
  const [isActiveSwitchOn, setIsActiveSwitchOn] = useState(false);
  const [auth, setAuth] = useState();

  const onActiveSwitchAction = () => {
    setIsActiveSwitchOn(!isActiveSwitchOn);
  };

  const [isAlertActiveSwitchOn, setIsAlertActiveSwitchOn] = useState(false);
  const onAlertActiveSwitchAction = () => {
    setIsAlertActiveSwitchOn(!isAlertActiveSwitchOn);
  };

  //Field Ref
  const udiRef = useRef();
  const sealRef = useRef();
  const devicenameRef = useRef();
  const rssiminRef = useRef();
  const rssimaxRef = useRef();
  const prioritylevelRef = useRef();
  const timeminRef = useRef();
  const timemaxRef = useRef();
  const resetcountRef = useRef();
  const batteryminRef = useRef();
  const batterywarnRef = useRef();
  const customerdeviceidRef = useRef();

  const regExp = /[a-zA-Z]/g;

  const TextField = styled.input`
	height: 32px;
	width: 350px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	&:hover {
		cursor: pointer;
	}
`;
  const ClearButton = styled(Button)`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
border-color: lightgrey;
height: 34px;
width: 64px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
color:darkgrey;
&:hover {
  cursor: pointer;
}
`;
  const fixedHeaderScrollHeight = '1150px'
  const fixedHeader = false;
  const dence = true;

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    //console.log('selectedRows:', selectedRows)
    //console.log(Object.values(selectedRows))
  };
  const customStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'transparant',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'transparant',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };
  const paginationComponentOptions = {
    selectAllRowsItem: 'true',
    selectAllRowsItemText: 'All',
    paginationPerPage: 30
  };
  const FilteringBLE_Interceptor = () => {
    const [datainterceptor, setDatainterceptor] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const timer = setInterval(() => {
        setDatainterceptor(prev => {
          ////console.log('Timer reload interceptor:', props.imei)
          fetchAccountdevices();
          return [...prev];
        });
      }, 10000);

      return () => {
        clearInterval(timer);
      };
    }, []);


    async function fetchAccountdevices(credentials) {
      //console.log(credentials)
      setLoading(true);
      const items = JSON.parse(localStorage.getItem('token'));
      //Verwijzing naar interceptor, hier staan ook de accounts in.
      return await fetch('https://interceptor.skylab.nl:2807/interceptor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'gfg_jwt_secret_key': auth
        },
        body: JSON.stringify({ accountid: items.id })
      })
        .then(setDatainterceptor(data))
        .then(setLoading(false))
    }


    // const fetchAccountdevices = async page => {
    //   const items = JSON.parse(localStorage.getItem('token'));
    //   setLoading(false);
    //   //Hier de interceptoren ophalen voor de groepen van het account
    //   const response = await axios.post(`https://interceptor.skylab.nl:2807/interceptor`,
    //     {
    //       accountid: items.id
    //     },
    //     {
    //       headers: {
    //         'gfg_jwt_secret_key' : auth
    //       }
    //     }
    //   );
    //   setDatainterceptor(response.data);
    //   setLoading(false);
    // };

    useEffect(() => {
      fetchAccountdevices();
    }, []);

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = datainterceptor.filter(
      item =>
        (item.udi && item.udi.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.devicename && item.devicename.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.seal && item.seal.toLowerCase().includes(filterText.toLowerCase())),

    );

    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };

      return (
        <FilterComponent_Interceptor onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);

    return (
      <>
        <DataTable
          columns={columns_interceptor}
          data={filteredItems}
          progressPending={loading}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          conditionalRowStyles={conditionalRowStyles}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          highlightOnHover
          customStyles={customStyles}
          fixedHeader={fixedHeader}
          fixedHeaderScrollHeight={fixedHeaderScrollHeight}
          dence={dence}
          paginationPerPage={20}
          defaultSortFieldId="Imei"
        />
      </>
    );
  };
  const FilterComponent_Interceptor = ({ filterText, onFilter, onClear }) => (
    <>
      <TextField
        id="search"
        type="text"
        placeholder="Filter on Imei, Name or Seal."
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>Clear</ClearButton>
    </>
  );


  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('token'));
    if (items) {
      setAccountid(items.id);
    }
    usergroups();
    fetchinterceptor();
    fetchpriority()
  }, []);

  const usergroups = async () => {
    const response = await axios.get(`https://interceptor.skylab.nl:2807/usergroup`);
    setUsergroup(response.data);
    //console.log(usergroup)
  };

  async function handleAccountAction(accountAction) {
    //Get accountid from local storage
    //console.log(accountid, accountAction)

    return fetch('https://interceptor.skylab.nl:2807/accountaction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ accountid: accountid, action: accountAction })
    })
      .then(function (response) {

        return response.text();
      })
      .catch(function (error) {
        console.error(error);
      })
  };

  const conditionalRowStyles = [

    {
      when: row => row.active,
      style: {
        backgroundColor: 'lightgreen',
      },
    },
    {
      when: row => !row.active,
      style: {
        backgroundColor: 'lightgrey',
      },
    }
  ];

  const columns_interceptor = [
    {
      name: 'iMei',
      selector: row => row.udi,
      sortable: true,
      Alignment: 'left'
    },
    {
      name: 'Seal',
      selector: row => row.seal,
      sortable: true,
      Alignment: 'left'
    },
    {
      name: 'Name',
      selector: row => row.devicename,
      sortable: true,
      Alignment: 'left'
    },
    {
      name: 'rssimin',
      selector: row => row.rssimin,
      sortable: true,
      center: 'true'
    },
    {
      name: 'rssimax',
      selector: row => row.rssimax,
      sortable: true,
      center: 'true'
    },
    {
      name: 'timemin',
      selector: row => row.timemin,
      sortable: true,
      center: 'true'
    },
    {
      name: 'timemax',
      selector: row => row.timemax,
      sortable: true,
      center: 'true'
    },
    {
      name: 'resetcount',
      selector: row => row.resetcount,
      sortable: true,
      center: 'true'
    },
    {
      name: 'Batt. min',
      selector: row => row.batterymin,
      sortable: true,
      center: 'true'
    },
    {
      name: 'Batt. warning',
      selector: row => row.batterywarning,
      sortable: true,
      center: 'true'
    },
    {
      name: 'In group(s)',
      selector: row => row.usergroupid.toString(),
      sortable: true,
      center: 'true'
    },
    {
      name: 'Active',
      selector: row => <input type="checkbox" readOnly checked={row.active} />,
      sortable: true,
      center: 'true'
    },
    {
      name: 'Alert active',
      selector: row => <input type="checkbox" readOnly checked={row.alertactive} />,
      sortable: true,
      center: 'true'
    },
    {
      name: 'Priority',
      selector: row => row.prioritytype,
      sortable: true,
      center: 'true',
    },
    {
      button: 'true',
      cell: (row) => (
        <Button variant="warning"
          onClick={(e) => {
            handleEditinterceptorClick(e, row.customerdeviceid)
          }}>
          Edit
        </Button>
      )
    },
    {
      button: 'true',
      cell: (row) => (
        <Button variant="warning"
          onClick={(e) => {
            const confirmBox = window.confirm(
              "Do you really want to delete this interceptor?"
            )
            if (confirmBox === true) {
              handleDelinterceptor(row.customerdeviceid)
            }
          }}>
          Delete
        </Button>
      )
    },
    {
      button: 'true',
      cell: (row) => (
        <Button variant="warning"
          onClick={(e) => {
            const confirmBox = window.confirm(
              "Do you really want to change te state of this interceptor?"
            )
            if (confirmBox === true) {
              handleActiveInterceptor(row.customerdeviceid, row.active)
            }
          }}>
          {row.active ? 'Deactivate' : 'Activate'}
        </Button>
      )
    },
    // {
    //   button: 'true',
    //   cell: (row) => (
    //     <Button variant="warning"
    //       onClick={(e) => {
    //         const confirmBox = window.confirm(
    //           "Do you really want to change te state of this alert?"
    //         )
    //         if (confirmBox === true) {
    //           handleActiveInterceptor(row.customerdeviceid, row.active)
    //         }
    //       }}>
    //       {row.alertactive ? 'Alert off' : 'Alert on'}
    //     </Button>
    //   )
    // },
    {
      name: 'id',
      selector: (row) => row.customerdeviceid,
      width: '0'
    }

  ];

  const columns_Group = [
    {
      name: 'Group',
      selector: row => row.usergroupname,
      sortable: true,
      Alignment: 'left',
    },
    {
      name: 'usergroupid',
      selector: (row) => row.usergroupid,
      width: '0',
    }
  ];

  // Functie om rijen vooraf te selecteren
  const rowSelectCriteria = useCallback(
    row => dbusergroup.toString().search(row.usergroupid) > -1,
    [dbusergroup]
  );


  const fetchinterceptor = async () => {
    const response = await axios.get(`https://interceptor.skylab.nl:2807/interceptorall`);
    setData(response.data);
  };

  const fetchpriority = async () => {
    const { data } = await axios.get(`https://interceptor.skylab.nl:2807/getpriority`);

    const priorityinterceptor = []
    data.forEach((value) => {
      priorityinterceptor.push({
        priorityid: value.priorityid,
        prioritytype: value.prioritytype
      });
    });

    setPrioritylevel([
      { priorityid: '0', prioritytype: 'Select.' },
      ...priorityinterceptor
    ])
  };

  async function handleDelinterceptor(id) {
    return fetch('https://interceptor.skylab.nl:2807/delinterceptor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'gfg_jwt_secret_key': auth
      },
      body: JSON.stringify({ customerdeviceid: id })
    })
      .then(function (response) {

        if (response.status === 200) {
          fetchinterceptor();
        }

        handleAccountAction('Delete interceptor:' + id)

        return response.text();
      })
      .catch(function (error) {
        console.error(error);
      })
  };

  async function addinterceptor(credentials) {
    return fetch('https://interceptor.skylab.nl:2807/addinterceptor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(function (response) {

        if (response.status === 200) {
          fetchinterceptor();
          handleAccountAction('Add interceptor:' + credentials.udi + ' ' + credentials.devicename)
        }
        else if (response.status === 599) {
          //console.log('Interceptor already in db')
          const confirmBox = window.confirm(
            'Interceptor already in the list.'
          )
          handleAccountAction('Add double interceptor:' + credentials.udi + ' ' + credentials.devicename)
        }

        return response.text();
      })
      .catch(function (error) {
        console.error(error);
      })
  };

  async function handleActiveInterceptor(id, currentstate) {
    //console.log(id, currentstate)
    return fetch('https://interceptor.skylab.nl:2807/activeinterceptor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customerdeviceid: id, active: currentstate })
    })
      .then(function (response) {

        if (response.status === 200) {
          fetchinterceptor();
        }

        handleAccountAction('De/Activate Interceptor:' + id + ' to State:' + currentstate)

        return response.text();
      })
      .catch(function (error) {
        console.error(error);
      })
  };

  const handleAddinterceptor = async e => {

    var result = await checkInputfields()
    if (!result)
      return;

    e.preventDefault();

    await addinterceptor({
      udi: udiRef.current.value,
      devicename: devicenameRef.current.value,
      rssimin: rssiminRef.current.value,
      rssimax: rssimaxRef.current.value,
      timemin: timeminRef.current.value,
      timemax: timemaxRef.current.value,
      resetcount: resetcountRef.current.value,
      batterymin: batteryminRef.current.value,
      batterywarning: batterywarnRef.current.value,
      active: isActiveSwitchOn,
      alertactive: isAlertActiveSwitchOn,
      usergroup: selectedRows,
      seal: sealRef.current.value,
      prioritylevel: prioritylevelRef.current,
    });
  }

  const handlePrioritySelect = (selectedOption) => {
    prioritylevelRef.current = selectedOption.nativeEvent.target.selectedIndex;
    console.log(`Option selected:`, selectedOption.nativeEvent.target.selectedIndex);
    console.log('prioritylevelRef:', prioritylevelRef.current)
  };

  const handleEditinterceptorClick = async (e, id) => {
    //console.log('id:', id)
    setHeadertext('Interceptor edit');
    setBtnSavetext('Save this interceptor')
    setButtonAvailable(true)
    setImeiFieldDisabled(true)
    try {
      const response = await fetch('https://interceptor.skylab.nl:2807/getinterceptorbyid', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'gfg_jwt_secret_key': auth
        },
        body: JSON.stringify({ customerdeviceid: id })
      });
      if (!response.ok) {
        console.log('Network response was not ok.');
      }
      const data = await response.json();
      console.log('Interceptordata:', data)

      rssiminRef.current.value = data[0].rssimin;
      rssimaxRef.current.value = data[0].rssimax;
      timeminRef.current.value = data[0].timemin;
      timemaxRef.current.value = data[0].timemax;
      batteryminRef.current.value = data[0].batterymin;
      batterywarnRef.current.value = data[0].batterywarning;
      setIsAlertActiveSwitchOn(data[0].alertactive);
      setIsActiveSwitchOn(data[0].active);
      setDbusergroup(data[0].usergroupid);
      udiRef.current.value = data[0].udi;
      devicenameRef.current.value = data[0].devicename;
      resetcountRef.current.value = data[0].resetcount;
      customerdeviceidRef.current.value = data[0].customerdeviceid;
      sealRef.current.value = data[0].seal;
      prioritylevelRef.current = data[0].priorityid;

console.log('prioritylevelRef.current:',prioritylevelRef.current);


    } catch (error) {
      console.log('Error', error)
    }
  }

  const handleUpdateInterceptor = async e => {

    var result = await checkInputfields()
    if (!result)
      return;

    e.preventDefault();

    await updateInterceptor({
      devicename: devicenameRef.current.value,
      active: isActiveSwitchOn,
      rssimin: rssiminRef.current.value,
      rssimax: rssimaxRef.current.value,
      timemin: timeminRef.current.value,
      timemax: timemaxRef.current.value,
      resetcount: resetcountRef.current.value,
      batterymin: batteryminRef.current.value,
      batterywarning: batterywarnRef.current.value,
      alertactive: isAlertActiveSwitchOn,
      prioritylevel: prioritylevelRef.current,
      customerdeviceid: customerdeviceidRef.current.value,
      usergroup: selectedRows,
      seal: sealRef.current.value
    });
  }

  async function checkInputfields() {
    //Check if all fields are there and right
    if (selectedRows.length === 0) {
      alert('Please select a group');
      return false;
    }
    if (devicenameRef.current.value === '') {
      alert('Please fill in the interceptor name');
      return false;
    }
    if (rssiminRef.current.value === '') {
      alert('Please fill in the rssi min');
      return false;
    }
    if (rssimaxRef.current.value === '') {
      alert('Please fill in the rssi max');
      return false;
    }

    if (regExp.test(udiRef.current.value)) {
      alert('Imei are numbers only.')
      return false;
    }

    if (regExp.test(sealRef.current.value)) {
      alert('Seal are numbers only.')
      return false;
    }

    if (udiRef.current.value.length !== 15) {
      alert('Imei length must be 15 numbers.')
      return false
    }

    if (rssiminRef.current.value > 0) {
      alert('rssi min must be negative.')
      return false;
    }

    if (rssimaxRef.current.value > 0) {
      alert('rssi max must be negative.')
      return false;
    }

    if (prioritylevelRef.current === null || prioritylevelRef.current === 0) {
      alert('Select priority level.')
      return false;
    }

    if (resetcountRef.current.value < 0) {
      alert('Reset count must be a positive number.')
      return false;
    }

    if (batteryminRef.current.value < 0 || batteryminRef.current.value < 3.1) {
      alert('Battery min must be a positive number greater than 3.1.')
      return false;
    }

    if (batterywarnRef.current.value < 0 || batterywarnRef.current.value < 3.2) {
      alert('Battery warn must be a positive number greater than 3.2.')
      return false;
    }

    if (batterywarnRef.current.value <= batteryminRef.current.value) {
      alert('Battery warn must be greater than Battery min.')
      return false;
    }

    if (timemaxRef.current.value < 0 || timemaxRef.current.value > 99999) {
      alert('Time max must be between 0 and 99999.')
      return false;
    }

    if (timeminRef.current.value < 0 || timeminRef.current.value > 99999) {
      alert('Time min must be between 0 and 99999.')
      return false;
    }

    if (timeminRef.current.value > timemaxRef.current.value) {
      alert('Time min must be smaller than Time max.')
      return false;
    }

    if (timemaxRef.current.value < timeminRef.current.value) {
      alert('Time max must be greater than Time min.')
      return false;
    }

    return true;
  }

  async function updateInterceptor(credentials) {
    //console.log('Update Interceptor')
    //console.log('updateInterceptor credentials:', credentials)

    return fetch('https://interceptor.skylab.nl:2807/interceptorupdate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(function (response) {

        if (response.status === 200) {
          fetchinterceptor();
          //handleAccountAction('Edit interceptor:' + credentials.account)
          setBtnSavetext("Interceptor updated")
        }
        return response.text();
      })
      .catch(function (error) {
        console.error(error);
      })
  }

  return (
    <>
      <div className='cForm'>

        <div className='cInterceptor'>
          <Form.Label className='cLabel'>{headertext}</Form.Label>
        </div>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Interceptor name</InputGroup.Text>
          <Form.Control
            //onChange={e => setDevicename(e.target.value)}
            placeholder="Name"
            aria-describedby="basic-addon1"
            ref={devicenameRef}
            required={true}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Imei</InputGroup.Text>
          <Form.Control
            placeholder="Imei"
            aria-describedby="basic-addon1"
            ref={udiRef}
            required={true}
            disabled={isImeiFieldDisabled}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Seal</InputGroup.Text>
          <Form.Control
            required={true}
            placeholder="Seal number"
            aria-describedby="basic-addon1"
            ref={sealRef}
            disabled={isImeiFieldDisabled}
          />
        </InputGroup>

        Alert config

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">rssi min</InputGroup.Text>
          <Form.Control
            required={true}
            placeholder="rssi min"
            aria-describedby="basic-addon1"
            ref={rssiminRef}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">rssi max</InputGroup.Text>
          <Form.Control
            placeholder="rssi max"
            aria-describedby="basic-addon1"
            ref={rssimaxRef}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Time min</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Time min"
            aria-describedby="basic-addon1"
            ref={timeminRef}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Time max</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Time max"
            aria-describedby="basic-addon1"
            ref={timemaxRef}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Reset Count</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Reset Count"
            aria-describedby="basic-addon1"
            ref={resetcountRef}
            value={3}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Battery min</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Battery min"
            aria-describedby="basic-addon1"
            ref={batteryminRef}
            value={3.1}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">Battery warn</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="Battery warn"
            aria-describedby="basic-addon1"
            ref={batterywarnRef}
            value={3.2}
          />
        </InputGroup>

        <FloatingLabel className='cSelector' controlId="selectedPriority" label="Priority level:">
          <FormSelect
            onChange={handlePrioritySelect}
            name="PrioritySelect"
            //defaultValue={prioritylevelRef.current.value}
          >
            {prioritylevel.map((option) => (
              <option key={option.priorityid} value={option.priorityid} selected={option.priorityid == prioritylevelRef.current ? true:false} >
                {option.prioritytype}
              </option>
            ))}
          </FormSelect>
        </FloatingLabel>

        <Switch className='cSwitch'
          defaultChecked={false}
          onChange={onActiveSwitchAction}
          checked={isActiveSwitchOn}
          type="switch"
          id="custom-switch"
          label="Interceptor active"
          reverse
        />

        <Switch className='cSwitch'
          defaultChecked={false}
          onChange={onAlertActiveSwitchAction}
          checked={isAlertActiveSwitchOn}
          type="switch"
          id="custom-switch"
          label="Alert active"
          reverse
        />

        <Form.Control
          hidden={true}
          type="text"
          placeholder="customerdeviceid"
          aria-describedby="basic-addon1"
          ref={customerdeviceidRef}
        />

        Groups

        {true && <div>
          <DataTable
            columns={columns_Group}
            data={usergroup}
            selectableRows
            onSelectedRowsChange={handleChange}
            selectableRowsHighlight={true}
            selectableRowSelected={rowSelectCriteria}
          />
        </div>}

        <Button onClick={handleAddinterceptor}
          hidden={ButtonAvailable}>
          Add this interceptor
        </Button>

        <Button onClick={handleUpdateInterceptor}
          hidden={!ButtonAvailable}>
          Update interceptor
        </Button>
      </div>

      <div className='cTable'>
        <div>
          <Form.Label className='cLabel' htmlFor="inputPassword5">Interceptor overview</Form.Label>
        </div>
        <FilteringBLE_Interceptor></FilteringBLE_Interceptor>
      </div>
    </>
  );
}
