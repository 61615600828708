import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import moment from 'moment';

//https://mui.com/x/react-charts/
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';

import FormSelect from 'react-bootstrap/FormSelect'
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import loadlogo from './images/loading.gif';

var lasthours = 1;
var devcount = [];
var devdate = [];
var myArray = [];

export default function Barchart() {
    const [interceptordata, setInterceptordata] = useState([]);
    const [data, setData] = useState([]);
    const [devudi, setDevudi] = useState('')
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false);
    const [chartvisible, setChartvisible] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [lastdatadate, setLastdatadate] = useState();

    const hourRef = useRef(1);

    const fetchInterceptordata = async (credentials) => {
        setLoading(true)
        setChartvisible(false)
        const response = await axios.post(`https://interceptor.skylab.nl:2807/interceptordata`,
            {
                udi: credentials,
                hour: hourRef.current,
                startdate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                enddate: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
            }
        );
        setInterceptordata(response.data);

        devcount = []
        devdate = []
        myArray = []

        response.data.map((item) => (devcount.push(item.devicecount)))
        response.data.map((item) => (devdate.push(moment(item.received).format('HH:mm:ss DD-MM-YYYY'))))

        response.data.map((item) => (myArray.push({ x: moment(item.received).format('HH:mm:ss DD-MM-YYYY'), y: item.devicecount })));
        setLoading(false)
        setChartvisible(true)
    };

    useEffect(() => {
        fetchlastdatadate()
        fetchAccountdevices()
        console.log(startDate)
    }, []);


    const setdate = (date) =>{
        console.log('date:',date)
        setStartDate(date)
        setEndDate(date)
    }


    function GenDatePicker() {
        return (
            <>
                <input className='cInput' value="Start date:" readOnly='true' ></input>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setdate(date)}
                    dateFormat="dd-MM-yyyy"
                    timeFormat="HH:mm"
                    showWeekNumbers
                    maxDate={startDate}
                    minDate={lastdatadate}
                    showTimeSelect
                />

                <input className='cInput' value="End date:" readOnly='true'></input>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd-MM-yyyy"
                    timeFormat="HH:mm"
                    showWeekNumbers
                    maxDate={new Date()}
                    minDate={startDate}
                    showTimeSelect
                />
                {/* {showgobutton && <Button type="button" onClick={handleGo}>Go</Button>} */}
            </>
        );
    }

    const fetchlastdatadate = async page => {
        const response = await axios.get(`https://interceptor.skylab.nl:2807/getlastdatadate`)
        setLastdatadate(response.data[0].received)   //'Fri Nov 27 2024 14:10:44'
    }

    const fetchAccountdevices = async page => {

        const items = JSON.parse(localStorage.getItem('token'));

        const { data } = await axios.post(`https://interceptor.skylab.nl:2807/interceptor`,
            { accountid: items.id }
        );

        const results = []
        data.forEach((value) => {
            results.push({
                value: value.customerdeviceid,
                label: value.devicename,
                udi: value.udi
            });
        });
        // Update the options state
        setOptions([
            { label: 'Select one.', udi: '' },
            ...results
        ])
    };

    const handleInterceptionSelect = (e) => {
        for (let node of e.target.children) {
            if (node.value === e.target.value) {
                const Udi = node.getAttribute('data-id');
                //console.log(Udi)
                setDevudi(Udi); // Update `devudi` state
                setChartvisible(true)
                fetchInterceptordata(Udi);
            }
        }
    };

    return (
        <>
            <header className="App-header">
                Interceptor received devices {moment(startDate).format('DD-MM-YYYY HH:mm')} to {moment(endDate).format('DD-MM-YYYY HH:mm')}.
            </header>

            {loading && <div className='cLoading'>
                <p>Loading...
                    <img src={loadlogo} height={50} width={50} alt="Loading" />
                </p>
            </div>
            }

            <GenDatePicker></GenDatePicker>

            <FloatingLabel className='cSelector' controlId="selectedInterseptor" label="Interceptor:">
                <FormSelect onChange={handleInterceptionSelect}
                    name="selectedInterseptor"
                    id="selInt">
                    {options.map((option) => (
                        <option key={option.value} data-id={option.udi}>{option.label + ' - ' + option.udi}</option>
                    ))}
                </FormSelect>
            </FloatingLabel>



            {chartvisible && <BarChart grid={{ horizontal: true }}
                series={[
                    { data: devcount, showMark: false, label: 'Devices' }
                ]}

                height={390}
                xAxis={[{ data: devdate, scaleType: 'band', tickMinStep: 3600 * 1000 * 24 }]}
                bottomAxis={{
                    tickLabelStyle: {
                        angle: 0,
                        textAnchor: 'middle',
                        // textAlign:'right',
                        // baselineShift:0,
                        // dominantBaseline:'bottom'
                    },
                }}
                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />}
        </>
    )
}
