//https://github.com/ms-aija/LeafletReact5minDemo/blob/master/src/components/Map.js#L22

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from "axios";
import moment from 'moment';
//import styled from 'styled-components';
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import FormSelect from 'react-bootstrap/FormSelect'
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import "./map.css"

import loadlogo from './images/loading.gif';

const position = [51, 4]

// --- (6) Create a custom marker ---
const customIcon = new Icon({
  iconUrl: 'https://login.iotdash.nl/images/Markers/Origineel/marker-icon-green.png',
  iconSize: [24, 41],
  popupAnchor: [0, -10]
})

// const Label = styled.label`
// 	height: 32px;
// 	width: 90px;
// 	border-radius: 3px;
// 	border-top-left-radius: 5px;
// 	border-bottom-left-radius: 5px;
// 	border-top-right-radius: 0;
// 	border-bottom-right-radius: 0;
// 	border: 1px solid #e5e5e5;
// 	text-align: center;
// 	padding-top: 9px
// `;

export default function Map() {
  const [options, setOptions] = useState([])
  const [udi, setUdi] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [iconPosition, seticonPosition] = useState([])
  const [loading, setLoading] = useState(false);
  const [showgobutton, setshowGobutton] = useState(false);

  const fetchAccountdevices = async page => {
    setLoading(false);
    const items = JSON.parse(localStorage.getItem('token'));

    const { data } = await axios.post(`https://interceptor.skylab.nl:2807/interceptor`,
      { accountid: items.id }
    );

    const results = []
    data.forEach((value) => {
      results.push({
        value: value.customerdeviceid,
        label: value.devicename,
        udi: value.udi,
        seal: value.seal
      });
    });
    // Update the options state
    setOptions([
      { label: 'Select one.' },
      ...results
    ])
    setLoading(false);
  };

  async function getHistory(information) {

    return fetch('https://interceptor.skylab.nl:2807/history', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(information)
    })
      .then(data => data.json())
  }

  const handleHistory = async e => {
    e.preventDefault();

    setLoading(true)
    const result = await getHistory({
      udi,
      startDate,
      endDate
    });

    const positions = []

    result.forEach((value) => {
      //console.log(value.latitude, value.longitude, moment(value.updatetime).format('HH:mm:ss DD-MM-YY'))
      positions.push({
        lat: value.latitude,
        lng: value.longitude,
        date: moment(value.udatetime).format('HH:mm:ss DD-MM-YY')
      });
    });

    seticonPosition([
      { lat: 0, lng: 0 },
      ...positions
    ])
    setLoading(false)
  }

  function GenDatePicker() {
    return (
      <>
        <div>
          <input className='cInput' value="Start date:" readOnly='true' ></input>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd-MM-yyyy"
            showWeekNumbers
            maxDate={new Date()}
          />
          <input className='cInput' value="End date:" readOnly='true'></input>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd-MM-yyyy"
            showWeekNumbers
            maxDate={new Date()}
          />
          {showgobutton && <Button type="button" onClick={handleHistory}>Go</Button>}

          {loading && <div className='cLoading'>
            <p>Loading...
              <img src={loadlogo} height={50} width={50} alt="Loading" />
            </p>
          </div>
          }
        </div>
      </>
    );
  }

  function handleInterceptionSelect(e) {
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        //console.log(node.getAttribute('data-id'))
        setUdi(node.getAttribute('data-id'))
        if (node.getAttribute('data-id') !== '*')
          setshowGobutton(true)
        else
          setshowGobutton(false)
      }
    }
  }

  useEffect(() => {
    fetchAccountdevices();
  }, []);

  return (
    <>
      <div className='cdivInterceptor'>
        <FloatingLabel className='cSelector' controlId="selectedInterseptor" label="Interceptor:">
          <FormSelect onChange={handleInterceptionSelect}
            name="selectedInterseptor">
            {options.map((option) => (
              <option key={option.value} data-id={option.udi}>{option.udi} - {option.label}  </option>
            ))}
          </FormSelect>
        </FloatingLabel>
      </div>

      <div>
        <GenDatePicker></GenDatePicker>
      </div>

      <div className="cMapdiv">
        {/* --- Add leaflet map container --- */}
        <MapContainer center={position} zoom={6} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {iconPosition.map((position, i) => (
            < Marker position={position}
              icon={customIcon}
              key={'icon_' + i}>
              <Popup
                key={'pop_' + i}>
                {position.date + ' (' + i + ')'}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </>
  )
}

