//https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
//http://www.sha1-online.com/
//https://www.javatpoint.com/saving-token-in-the-local-storage-in-mean-stack

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './login.css';

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [id, setId] = useState();
  const [usergroup, setUsergroup] = useState([]);
  const [auth, setAuth] = useState();

  async function loginUser(credentials) {
    //console.log(credentials)
    return await fetch('https://interceptor.skylab.nl:2807/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }

  const handleSubmit = async e => {
    e.preventDefault();
    console.log('handleSubmit')
    const token = await loginUser({
      username,
      password,
      id,
      usergroup
    });
    setToken(token);
  }

  return (
    <div className="login-wrapper">
      <h1>Log in</h1>
      <form onSubmit={handleSubmit}>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">*</InputGroup.Text>
          <Form.Control
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={e => setUserName(e.target.value)}
          />
        </InputGroup>

        <InputGroup className="mb-2">
          <InputGroup.Text id="basic-addon1">*</InputGroup.Text>
          <Form.Control
            type="password"
            placeholder="Password"
            aria-label="Password"
            aria-describedby="basic-addon1"
            onChange={e => setPassword(e.target.value)}
          />
        </InputGroup>

        <Button type="submit" variant="primary"
          onClick={(e) => {
          }}>
          Log in
        </Button>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}